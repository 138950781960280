.abonnement {
    .blockcontent {
        .headerPrix {
            display: flex;
            flex-direction: column;
            .left {
                border-radius: 15px;
                width: 100%;
                height: 100px;
                padding: 15px;
                text-align: center;
                box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.16);
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .right {
                border-radius: 15px;
                width: 100%;
                height: 100px;
                padding: 15px;
                text-align: center;
                box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.16);
                margin-bottom: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
    #selectionDepartement {
        h2{
            font-size: 20px;
            font-weight: 600;
        }
        .from-control {
            select {
                height: 66px;
            }
        }
        .listeDepartementSelect {
            h2 {
            }
            .listeDepartementHeader {
                justify-content: space-between;
                border-bottom: 1px solid #E5E5E5;
                .blockDepart {
                    border: none !important;
                    p{
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
                .blockPrix {
                    border: none !important;
                    p{
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
            }
            .listeDepartementBody {
                border-bottom: 1px solid #E5E5E5;
                div {
                    justify-content: space-between;
                    .blockDepart {
                        border: none !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .blockPrix {
                        border: none !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            display: block;
                            width: 24px!important;
                        }
                    }
                }
            }
            .listeDepartementFooter {
                justify-content: flex-end;
                .blockDepart {
                    border: none !important;
                    font-size: 20px;
                        font-weight: 600;
                }
                .blockPrix {
                    border: none !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .hiddenBlockReplace{
                        display: block;
                        width: 24px!important;
                    }
                }
            }
            .openPayment{
                a{
                    span{
                        display: none;
                    }
                }
            }
        }

    }
    #selectionCode{
        h2{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .from-control {
            select {
                align-items: center;
                padding: 10px 25px;
                width: 300px;
                text-align: center;
                margin-bottom: 35px;
                background: none;
                border: 2px solid black;
                border-radius: 10px;
                cursor: pointer;
                height: 66px;
            }
            a{
                width: 75px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    position: relative;
                    left: 5px;
                }
            }
        }
        .listeDepartementSelect {
            h2 {
            }
            .listeDepartementHeader {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #E5E5E5;
                .blockDepart {
                    border: none !important;
                    padding: 10px 0;
                    p{
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
                .blockPrix {
                    border: none !important;
                    padding: 10px 0;
                    p{
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .listeDepartementBody {
                border-bottom: 1px solid #E5E5E5;
                div {
                    display: flex;
                    justify-content: space-between;
                    .blockDepart {
                        border: none !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 33%;
                        padding: 10px 0;
                    }
                    .blockPrix {
                        border: none !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 33%;
                        padding: 10px 0;
                        text-align: center;
                        p{
                            width: 100%;
                        }
                        a{
                            display: block;
                            width: 24px!important;
                        }
                    }
                }
            }
            .listeDepartementFooter {
                display: flex;
                justify-content: flex-end;
                .blockDepart {
                    border: none !important;
                    font-size: 14px;
                    font-weight: 600;
                    width: 33%;
                    padding: 10px 0;
                }
                .blockPrix {
                    border: none !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 33%;
                    padding: 10px 0;
                    p{
                        text-align: center;
                    }
                    
                    .hiddenBlockReplace{
                        display: block;
                        width: 24px!important;
                    }
                }
            }
            
            .openPayment{
                a{
                    display: block;
                    text-decoration: none;
                    background: #43a1db;
                    color: white;
                    border-style: none;
                    border-radius: 10px;
                    padding: 10px 20px;
                    transition: 500ms;
                    width: 278px;
                    margin: 0 auto;
                    border: 1px solid #43a1db !important;
                    span{
                        display: none;
                    }
                }
            }
        }
    }
    #paimentBlock{
        input{
            width: 100%;
            border: 1px solid #939393;
            border-radius: 15px;
            padding: 15px;
        }            
    }
    #subcriptionCancel{
        .blockfiltre{
            border-bottom: 1px solid #E5E5E5;
            .block{

            }
        }
        .tableHeader{
            .trHeader{

            }
        }
        .tableBody{
            .tableLine{
                border-bottom: 1px solid #E5E5E5;
                .trBody{
                    border: none!important;
                    &:nth-child(2){
                        width:70%!important;
                        text-align: left;
                    }
                    &:nth-child(3){
                        width: 20%!important;    
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        a{
                            display: block;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .abonnement {
        .blockcontent {
            .headerPrix {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .left {
                    width: 45%;
                    p {
                    }
                }
                .right {
                    width: 45%;
                    p {
                    }
                }
            }
        }
        #selectionDepartement {
            width: 80%;
            margin: 0 auto;
            h2{
            }
            .from-control {
                select {
                }
            }
            .listeDepartementSelect {
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        p{
                        }
                    }
                    .blockPrix {
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                        }
                        .blockPrix {
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        .hiddenBlockReplace{
                        }
                    }
                }
                .openPayment{
                    a{
                        span{
                        }
                    }
                }
            }
    
        }
        #selectionCode{
            h2{
            }
            .from-control {
                select {
                    display: block;
                    margin: 0 auto 35px;
                }
                a{
                    span{
                    }
                }
            }
            .listeDepartementSelect {
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        p{
                        }
                    }
                    .blockPrix {
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                        }
                        .blockPrix {
                            p{
                            }
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        p{
                        }
                        
                        .hiddenBlockReplace{
                        }
                    }
                }
                
                .openPayment{
                    a{
                        span{
                        }
                    }
                }
            }
        }
        #paimentBlock{
            input{
            }            
        }
        #subcriptionCancel{
            width: 80%;
            margin: 0 auto;
            .blockfiltre{
                .block{
    
                }
            }
            .tableHeader{
                .trHeader{
    
                }
            }
            .tableBody{
                .tableLine{
                    .trBody{
                        &:nth-child(3){
                        }
                        &:nth-child(2){
                        }
                        &:nth-child(3){
                            a{
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 960px) {
    .abonnement {
        .blockcontent {
            width: 910px!important;
            margin: 0 auto 70px!important;
            .headerPrix {
                .left {
                    width: 425px;
                    p {
                    }
                }
                .right {
                    width: 425px;
                    p {
                    }
                }
            }
        }
        form{
            width: 100%!important;
        }
        #selectionDepartement {
            width: 910px!important;
            margin: 0 auto 70px!important;
            h2{
            }
            .from-control {
                select {
                    &.departementSelect{
                        display:block;
                        width: 261px!important;
                        margin: 20px auto 25px!important;
                    }
                }
            }
            .listeDepartementSelect {
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        p{
                        }
                    }
                    .blockPrix {
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                        }
                        .blockPrix {
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        .hiddenBlockReplace{
                        }
                    }
                }
                .openPayment{
                    a{
                        span{
                        }
                    }
                }
            }
    
        }
        #selectionCode{
            width: 910px!important;
            margin: 0 auto 70px!important;
            h2{
            }
            .from-control {
                select {
                }
                a{
                    span{
                    }
                }
            }
            .listeDepartementSelect {
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        p{
                        }
                    }
                    .blockPrix {
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                        }
                        .blockPrix {
                            p{
                            }
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        p{
                        }
                        
                        .hiddenBlockReplace{
                        }
                    }
                }
                
                .openPayment{
                    margin-top: 35px;
                    a{
                        span{
                        }
                    }
                }
            }
        }
        #paimentBlock{
            width: 910px!important;
            margin: 0 auto 70px!important;
            input{
            }            
        }
        #subcriptionCancel{
            width: 910px!important;
            margin: 20px auto 70px!important;
            .blockfiltre{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 35px;
                border: none;
                .block{
    
                }
            }
            .tableHeader{
                display: flex!important;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;
                .trHeader{
                    text-align: center;
                    width: 33%;
                    &:nth-child(2){
                        
                    }
                    &:nth-child(3){
                        width: 15%!important;
                    }
                }
            }
            .tableBody{
                width: 100%!important;
                margin: 0!important;
                .tableLine{
                    display: flex!important;
                    justify-content: space-between;
                    align-items: center;
                    .trBody{
                        width: 33%!important;
                        &:nth-child(2){
                            width: 33%!important;
                            text-align: center;
                        }
                        &:nth-child(3){
                            width: 15%!important;
                            justify-content: center;
                            a{
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .abonnement {
        .blockcontent {
            .headerPrix {
                .left {
                    p {
                    }
                }
                .right {
                    p {
                    }
                }
            }
        }
        form{
        }
        #selectionDepartement {
            h2{
            }
            .from-control {
                flex-direction: column;
                select {
                    &.departementSelect{
                    }
                }
            }
            .listeDepartementSelect {
                
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        width: auto!important;
                        p{
                            text-align: left;
                        }
                    }
                    .blockPrix {
                        width: 15%!important;
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                            width: auto!important;
                            p{
                                text-align: left;
                            }
                        }
                        .blockPrix {
                            width: 15%!important;
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        width: 15%!important;
                        .hiddenBlockReplace{
                        }
                    }
                }
                .openPayment{
                    a{
                        width: 293px!important;
                        transition: 500ms;
                        span{
                            display: inline;
                            position: relative;
                            top: 1px;
                            transition: left 500ms;
                        }
                        &:hover{
                            span{
                                left: 5px;
                            }
                        }
                    }
                }
            }
    
        }
        #selectionCode{
            h2{
            }
            .from-control {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                h2{
                    display: block;
                    width: 100%;
                }
                select {
                    margin: 0 5px 0 0;
                    width: 261px;
                }
                a{
                    span{
                    }
                }
            }
            .listeDepartementSelect {
                width: 100%;
                h2 {
                }
                .listeDepartementHeader {
                    .blockDepart {
                        p{
                        }
                    }
                    .blockPrix {
                        width: 15%;
                        p{
                        }
                    }
                }
                .listeDepartementBody {
                    div {
                        .blockDepart {
                            width: auto;
                        }
                        .blockPrix {
                            width: 15%;
                            p{
                            }
                            a{
                            }
                        }
                    }
                }
                .listeDepartementFooter {
                    .blockDepart {
                    }
                    .blockPrix {
                        width: 15%;
                        p{
                        }
                        
                        .hiddenBlockReplace{
                        }
                    }
                }
                
                .openPayment{
                    a{
                        span{
                        }
                    }
                }
            }
        }
        #paimentBlock{
            input{
            }            
        }
        #subcriptionCancel{
            .blockfiltre{
                .block{
    
                }
            }
            .tableHeader{
                width: 100%!important;
                justify-content: space-between!important;
                .trHeader{
                    border: none!important;
                    text-align: center;
                    width: 33%!important;
                    &:nth-child(2){
                        
                    }
                    &:nth-child(3){
                        width: 15%!important;
                    }
                }
            }
            .tableBody{
                .tableLine{
                    width: 100%!important;
                    justify-content: space-between!important;
                    .trBody{
                        &:nth-child(2){
                        }
                        &:nth-child(3){
                            a{
                            }
                        }
                    }
                }
            }
        }
    }
}