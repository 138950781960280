.formProConnexion {
  width: 95%;
  margin: 100px auto;
}
.formProConnexion .blockleft {
  margin-bottom: 50px;
  text-align: center;
}
.formProConnexion .blockleft h2 {
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: 500;
}
.formProConnexion .blockleft input[type=text],
.formProConnexion .blockleft input[type=email],
.formProConnexion .blockleft input[type=password] {
  width: 100%;
  height: 47px;
  margin-bottom: 25px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 10px;
  font-size: 16px;
}
.formProConnexion .blockleft button[type=submit] {
  width: 100%;
  height: 80px;
  font-size: 28px;
  font-weight: 800;
}
.formProConnexion .blockleft .form-check {
  margin-bottom: 10px;
}
.formProConnexion .blockleft .btn.btn-link {
  display: block;
  width: 100%;
  margin: 15px 0;
  color: #43a1db;
  font-size: 16px;
  font-weight: 500;
}
.formProConnexion .blockleft .row .col-md-6 .invalid-feedback {
  color: red;
}
.formProConnexion .blockright {
  text-align: center;
}
.formProConnexion .blockright h2 {
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: 500;
}
.formProConnexion .blockright input[type=text],
.formProConnexion .blockright input[type=email],
.formProConnexion .blockright input[type=password] {
  width: 100%;
  height: 47px;
  margin-bottom: 25px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 10px;
  font-size: 16px;
}
.formProConnexion .blockright button[type=submit] {
  width: 100%;
  height: 80px;
  font-size: 28px;
  font-weight: 800;
}

@media only screen and (min-width: 960px) {
  .formProConnexion {
    width: 860px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .formProConnexion .blockleft {
    width: 45%;
  }
  .formProConnexion .blockright {
    width: 45%;
  }
  .formProConnexion .row .col-md-6 .invalid-feedback {
    color: red;
  }
}
#btn2 a {
  font-size: 10px;
}

#deleteVideo2 {
  background: url("../img/Icon feather-trash-2@2x.png") top left no-repeat rgba(0, 0, 0, 0);
  background-size: contain;
  color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 25px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  z-index: 10000;
}

.btn.btn-primary {
  display: block;
  background: #43a1db;
  color: white;
  border-radius: 15px;
  border: none;
  padding: 15px 45px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: 500ms;
  width: 280px;
  text-align: center;
  height: 50px;
  margin: 0 auto 20px;
}

.blocknavigation {
  width: 95%;
  margin: 75px auto 50px;
  display: flex;
  justify-content: center;
}
.blocknavigation nav ul {
  list-style: none;
}
.blocknavigation nav ul li {
  border-bottom: 1px solid #939393;
  width: 300px;
  text-align: center;
  padding: 10px 0;
  margin: 0 auto;
}
.blocknavigation nav ul li:last-child {
  border-bottom: none;
}
.blocknavigation nav ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
.blocknavigation nav ul li .currentLink {
  color: #2f2f2f;
}

.blockcontent {
  width: 95%;
  margin: 0 auto 30px;
}
.blockcontent h1 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
.blockcontent h2 {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
  text-align: center;
  margin-bottom: 10px;
}
.blockcontent h3 {
  font-size: 20px;
  font-weight: 600;
  color: #2f2f2f;
  margin: 20px 0;
  text-align: center;
}
.blockcontent .informations {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 auto 15px;
}
.blockcontent .formAlign select,
.blockcontent .formAlign input {
  width: 100%;
  height: 35px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 10px;
  margin-bottom: 25px;
  font-size: 16px;
}
.blockcontent .formAlign textarea {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding: 10px;
  margin-bottom: 25px;
  font-size: 16px;
}
.blockcontent .block1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.blockcontent .block1 .blockImgVideoPro {
  margin-bottom: 30px;
}
.blockcontent .block1 .blockImgVideoPro input[type=file] {
  height: 35px;
  opacity: 0;
  width: 250px;
  margin-bottom: 0;
}
.blockcontent .block1 .blockImgVideoPro label {
  margin-left: -300px;
  font-size: 16px;
  font-weight: 400;
  color: #2f2f2f;
}
.blockcontent .block1 .blockImgVideoPro .custom-file input[type=file] {
  height: 35px;
  opacity: 0;
  width: 300px;
}
.blockcontent .block1 .blockImgVideoPro .videoCropperPreview {
  margin-left: 300px;
  margin-top: -20px;
}
.blockcontent .block1 .blockImgVideoPro .videoCropperPreview img {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  border: 4px solid #939393;
}
.blockcontent .block1 .blockImgVideoPro .videoCropperPreview #deleteVideo2,
.blockcontent .block1 .blockImgVideoPro .videoCropperPreview #deleteVideo {
  background: url("../img/Icon feather-trash-2@2x.png") top left no-repeat rgba(0, 0, 0, 0);
  background-size: contain;
  color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 25px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  z-index: 10000;
}
.blockcontent .block1 .blockImgVideoPro .blockvideo {
  margin-top: 10px;
}
.blockcontent .block1 .blockImgVideoPro .blockvideo video {
  max-height: 250px;
  max-width: 300px;
  width: auto;
}
.blockcontent .block1 .blockImgVideoPro #cropper {
  margin-bottom: 50px;
}
.blockcontent .block1 .blockImgVideoPro #cropper .bgmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockcontent .block1 .blockImgVideoPro #cropper .bgmodal .img-preview-crop {
  border: 5px solid #2f2f2f;
}
.blockcontent .block1 .blockImgVideoPro #cropper .bgmodal .containerModal {
  width: 95%;
  background: white;
  position: relative;
  padding: 30px;
  height: 85vh;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.blockcontent .block1 .blockImgVideoPro #cropper .bgmodal .containerModal .cropBtn {
  position: absolute;
  bottom: 30px;
  right: 39%;
}
.blockcontent .block1 .blockImgVideoPro #cropper .bgmodal .containerModal .cropBtn2 {
  position: absolute;
  bottom: 30px;
  right: 51%;
}
.blockcontent .block1 .blockImgVideoPro #cropper .imgCropperPreview img {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  border: 4px solid #939393;
}
.blockcontent .block1 .blockImgVideoPro #cropper .imgCropperPreview .btn.btn-warning {
  background: url("../img/Icon feather-trash-2@2x.png") top left no-repeat rgba(0, 0, 0, 0);
  background-size: contain;
  color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 25px;
  cursor: pointer;
}
.blockcontent .block1 .blockInfoPro {
  width: 100%;
}
.blockcontent .block1 .blockagence {
  width: 100%;
}
.blockcontent .block1 .blockagence input {
  width: 338px;
  height: 47px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 10px;
  margin-bottom: 25px;
  font-size: 16px;
}
.blockcontent .blockPerimetre {
  margin-top: 20px;
  width: 100%;
}
.blockcontent .blockPerimetre h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 28px;
  color: #2F2F2F;
}
.blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine {
  margin-bottom: 35px;
}
.blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock {
  width: 100%;
}
.blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock select {
  width: 100%;
  height: 35px;
  border-radius: 15px;
  text-align: center;
  border: 2px solid #2f2f2f;
  margin-bottom: 20px;
}
.blockcontent .blockPerimetre #perimetre .addperimettre {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockcontent .blockPerimetre #perimetre .addperimettre .btn {
  width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockcontent .blockPerimetre #perimetre .addperimettre .btn span {
  margin-left: 5px;
}
.blockcontent .bloclService {
  margin-top: 50px;
  width: 100%;
}
.blockcontent .bloclService h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 28px;
  color: #2F2F2F;
}
.blockcontent .bloclService .blockFull {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.blockcontent .bloclService .blockFull .block .checkVente, .blockcontent .bloclService .blockFull .block .checkChasse, .blockcontent .bloclService .blockFull .block .checkRelation {
  margin-bottom: 10px;
}
.blockcontent .bloclService .blockFull .block input[type=checkbox] {
  border-radius: 100%;
  width: auto;
  height: auto;
  border: unset;
  padding-left: 0;
  margin-bottom: 0;
}
.blockcontent .bloclService .blockFull .block label {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
  margin-left: 5px;
}
.blockcontent .blockpasswword {
  width: 100%;
}
.blockcontent .blockpasswword h2 {
  margin: 20px 0 50px;
  font-size: 28px;
  color: #2F2F2F;
}
.blockcontent .blockpasswword .form-control {
  width: 100%;
  margin: 0 auto;
}
.blockcontent .blockpasswword .form-control input {
  width: 100%;
  height: 35px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 5px;
  margin-bottom: 25px;
}
.blockcontent .valide_form {
  text-align: center;
  margin: 20px 0 30px;
}
.blockcontent .valide_form input {
  background: #43a1db;
  color: white;
  border-radius: 15px;
  border: none;
  padding: 15px 45px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: 500ms;
  width: 184px;
  height: 50px;
}
.blockcontent .valide_form input:hover {
  background: #3186ba;
}
.blockcontent .valide_form span {
  display: none;
}
.blockcontent .form-control {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.blockcontent .form-control input {
  width: auto;
  height: auto;
  padding-left: 0;
  margin-left: 0;
}
.blockcontent .form-control input[type=text] {
  width: 100%;
  height: 35px;
  border-radius: 15px;
  border: 1px solid #939393;
  padding-left: 15px;
  margin-bottom: 25px;
}
.blockcontent .form-control #telephone {
  margin-left: 70px;
}
.blockcontent .form-control h2 {
  margin-bottom: 10px;
}
.blockcontent .form-control label {
  margin-left: 5px;
}

@media only screen and (min-width: 360px) {
  .blocknavigation {
    width: 90%;
  }
  .blockcontent {
    width: 90%;
  }
}
@media only screen and (min-width: 600px) {
  .blocknavigation nav {
    width: 100%;
  }
  .blocknavigation nav ul {
    display: flex;
  }
  .blocknavigation nav ul li {
    width: auto;
    border-left: 1px solid #939393;
    border-bottom: none;
    padding: 10px 20px;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blocknavigation nav ul li:first-child {
    border-left: none;
  }
  .blocknavigation nav ul li a {
    font-size: 16px;
  }
  .blocknavigation .pro ul {
    flex-wrap: wrap;
  }
  .blocknavigation .pro ul li {
    width: auto;
    border-left: none;
    border-bottom: 1px solid #939393;
    padding: 10px 20px;
    width: 33%;
  }
  .blocknavigation .pro ul li:nth-child(0n+4) {
    border-bottom: none;
  }
  .blocknavigation .pro ul li a {
    font-size: 16px;
  }

  .blockcontent .formAlign select,
.blockcontent .formAlign input {
    width: 338px;
    height: 45px;
  }
  .blockcontent .formAlign textarea {
    width: 338px;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock {
    width: 338px;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock select {
    width: 338px;
  }
  .blockcontent .form-control input[type=text] {
    width: 338px;
    height: 45px;
  }
}
@media only screen and (min-width: 800px) {
  .blocknavigation nav ul li {
    padding: 20px 50px;
  }
  .blockcontent .block1 {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .blockcontent .block1 .blockImgVideoPro {
    width: 100%;
  }
  .blockcontent .block1 .blockInfoPro {
    width: auto;
  }
  .blockcontent .block1 .blockagence {
    width: auto;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block {
    flex-direction: row;
    flex-wrap: wrap;
    width: 720px;
    margin: 0 auto;
  }
  .blockcontent .bloclService .blockFull {
    flex-direction: row;
  }
  .blockcontent .bloclService .blockFull .block {
    width: 30%;
  }
}
@media only screen and (min-width: 1280px) {
  .blocknavigation {
    width: 1200px;
  }
  .blocknavigation nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 890px;
    margin: 0 auto;
  }
  .blocknavigation nav ul li {
    border-left: 1px solid #939393;
    border-bottom: none;
    padding: 20px 30px;
    width: 300px;
    text-align: center;
  }
  .blocknavigation nav ul li:first-child {
    border-left: none;
  }
  .blocknavigation nav ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #939393;
  }
  .blocknavigation nav ul li .currentLink {
    color: #2f2f2f;
  }
  .blocknavigation .pro ul {
    flex-wrap: unset;
  }
  .blocknavigation .pro ul li {
    border-left: 1px solid #939393;
    border-bottom: none;
    height: 77px;
    width: 25%;
  }
  .blockcontent {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .blockcontent.profilPart {
    margin-top: 50px;
    justify-content: center;
  }
  .blockcontent.profilPart .blockpasswword {
    position: relative;
    left: unset;
    top: unset;
  }
  .blockcontent.profilPart .valide_form {
    position: relative;
    left: unset;
    top: unset;
  }
  .blockcontent h1 {
    display: block;
    width: 100%;
  }
  .blockcontent h2 {
    font-size: 16px;
    font-weight: 400;
    color: #939393;
    text-align: center;
    width: 100%;
  }
  .blockcontent .block1 {
    width: 300px;
  }
  .blockcontent .block1 .blockImgVideoPro {
    width: auto;
  }
  .blockcontent .block1 .blockImgVideoPro.center {
    width: 300px;
  }
  .blockcontent .block1 .blockImgVideoPro .videoCropperPreview img {
    width: 190px;
    height: 190px;
    border-radius: 100%;
    border: 4px solid #939393;
  }
  .blockcontent .block1 .blockInfoPro {
    width: 450px;
  }
  .blockcontent .block1 .blockInfoPro select,
.blockcontent .block1 .blockInfoPro input {
    width: 338px;
    height: 47px;
    border-radius: 15px;
    border: 1px solid #939393;
    padding-left: 10px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .blockcontent .block1 .blockInfoPro textarea {
    width: 338px;
    height: 360px;
    border-radius: 15px;
    border: 1px solid #939393;
    padding: 10px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .blockcontent .block1 .blockagence {
    width: 450px;
  }
  .blockcontent .block1 .valide_form {
    position: relative;
    left: unset;
    width: unset;
    top: unset;
  }
  .blockcontent .block1 .valide_form span {
    display: block;
    position: absolute;
    top: 12px;
    right: 96px;
    color: white;
    font-size: 22px;
  }
  .blockcontent .formAlign {
    width: 900px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    height: 2150px;
  }
  .blockcontent .formAlign select,
.blockcontent .formAlign input {
    width: 338px;
    height: 47px;
    border-radius: 15px;
    border: 1px solid #939393;
    padding-left: 10px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .blockcontent .formAlign textarea {
    width: 338px;
    height: 360px;
    border-radius: 15px;
    border: 1px solid #939393;
    padding: 10px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .blockcontent .blockPerimetre {
    position: absolute;
    left: -300px;
    top: 1750px;
    width: 1200px;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block {
    width: auto;
    justify-content: flex-start;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock {
    width: auto;
    margin-right: 13px;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock:last-child {
    margin-right: 0;
  }
  .blockcontent .blockPerimetre #perimetre #lineselectperimetre .blockSelectLine .block .selectBlock select {
    width: 260px;
    height: 65px;
  }
  .blockcontent .bloclService {
    position: absolute;
    left: -300px;
    width: 1200px;
    top: 1980px;
  }
  .blockcontent .bloclService .blockFull .block {
    width: 30%;
  }
  .blockcontent .blockpasswword {
    width: 1200px;
    position: absolute;
    left: -300px;
    top: 2410px;
  }
  .blockcontent .blockpasswword .form-control {
    width: 338px;
    margin: 0 auto;
  }
  .blockcontent .blockpasswword .form-control input {
    width: 100%;
    height: 47px;
  }
  .blockcontent #btn_submit_form_custom {
    top: 2770px;
  }
  .blockcontent .valide_form {
    position: absolute;
    left: -300px;
    width: 1200px;
    top: 2340px;
  }
  .blockcontent .valide_form input {
    width: 184px;
    padding-left: 45px;
  }
  .blockcontent .valide_form span {
    display: block;
    position: absolute;
    top: 12px;
    right: 96px;
    color: white;
    font-size: 22px;
  }
  .blockcontent .form-control {
    width: 338px;
  }
  .blockcontent .form-control #telephone {
    margin-left: 70px;
  }
  .blockcontent .form-control h2 {
    margin-bottom: 10px;
  }
  .blockcontent .form-control label {
    margin-left: 5px;
  }

  .upfooter footer {
    margin-top: 750px;
  }

  #btn2 a {
    font-size: 16px;
  }
}
#blockCopy {
  position: relative;
  height: 45px;
  margin-bottom: 20px;
}

#copy {
  height: 45px;
  padding: 12px;
  overflow: auto;
  outline: none;
  box-shadow: none;
  user-select: none;
  resize: none;
}

#copyBtn {
  position: absolute;
  display: block;
  background: none;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
#copyBtn svg {
  position: absolute;
  top: 9px;
  right: 15px;
}

.copiedtext {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  transform: translateY(-1em);
  color: #000;
  transition: all 0.5s;
}

.copied .copiedtext {
  opacity: 1;
  transform: translateY(-2em);
}

#card_ext {
  text-align: left;
}

#btnCartSee {
  display: block;
  background: #43a1db;
  color: white;
  border-radius: 15px;
  border: none;
  padding: 15px 45px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: 500ms;
  width: 280px;
  text-align: center;
  height: 50px;
  margin: 0 auto 20px;
}

#consulteventepro .form {
  margin: 35px auto;
  width: 90%;
}
#consulteventepro .form .addFilter {
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}
#consulteventepro .form h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #2F2F2F;
}
#consulteventepro .form h2 a {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 20px;
}
#consulteventepro .form .prix {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#consulteventepro .form .prix input {
  padding-left: 10px;
  margin: 25px 0 0px;
  border-radius: 10px;
  height: 35px;
  width: 100%;
  border: 2px solid black;
  height: 66px;
  width: 280px;
  font-size: 14px;
}
#consulteventepro .form .prix .message {
  margin: 30px auto 0;
  color: red;
}
#consulteventepro .form .block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}
#consulteventepro .form .block .btn.formbtn {
  border: 2px solid #2f2f2f;
  padding: 20px 10px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 25px;
  text-align: center;
  transition: 200ms;
  height: 66px;
  width: 280px;
  font-size: 14px;
}
#consulteventepro .form .block .btn.formbtn:hover {
  border-color: #46ce85;
}
#consulteventepro .form .block .btn.formbtn.selected {
  background: #46ce85;
  border-color: #33ad6c;
  color: white;
}
#consulteventepro .form .block .selectBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#consulteventepro .form .block .selectBlock select {
  text-align: center;
  border: 2px solid #2f2f2f;
  padding: 10px 10px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 25px;
  height: 66px;
  width: 280px;
  font-size: 14px;
}
#consulteventepro .submitForm {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
#consulteventepro .submitForm a {
  background: #43a1db;
  color: white;
  border-radius: 15px;
  border: none;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  margin-right: 10px;
}
#consulteventepro .submitForm a:nth-child(0n+2) {
  margin-left: 10px;
  margin-right: 0;
}
#consulteventepro .submitForm a:hover {
  background: #3186ba;
}
#consulteventepro .submitForm span {
  display: none;
}
#consulteventepro .blockSearch {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}
#consulteventepro .blockSearch .nothingSearch {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
#consulteventepro .blockSearch .search {
  margin-top: 40px;
  color: red;
  text-align: center;
  width: 102%;
}
#consulteventepro .blockSearch .bienBlock {
  margin: 50px auto;
  width: 100%;
  min-height: 680px;
  text-align: center;
  position: relative;
  border-radius: 15px;
}
#consulteventepro .blockSearch .bienBlock .footerAddBien {
  position: absolute;
  bottom: 0px;
  width: 90%;
  left: 0;
  right: 0;
  background: #ffffff;
  height: 68px;
  border-radius: 13px;
  margin: 0 auto 45px;
}
#consulteventepro .blockSearch .bienBlock .footerAddBien a {
  display: block;
  border: none;
  padding: 13px;
  color: #2F2F2F;
  transition: 500ms;
}
#consulteventepro .blockSearch .bienBlock .footerAddBien a:hover {
  transition: 500ms;
  border-color: #46CE85;
}
#consulteventepro .blockSearch .bienBlock .dateformat {
  position: relative;
  top: -20px;
  width: 80%;
  margin: 0 auto;
}
#consulteventepro .blockSearch .bienBlock .dateformat p {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #939393;
}
#consulteventepro .blockSearch .bienBlock .blockRecap {
  margin: 20px 13px;
  text-align: center;
}
#consulteventepro .blockSearch .bienBlock .blockRecap p {
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
}
#consulteventepro .blockSearch .bienBlock .blockRecap p:first-child {
  font-weight: 600;
}
#consulteventepro .blockSearch .relation {
  border-color: grey;
}
#consulteventepro .blockSearch .relation .headerBien {
  opacity: 0.5;
}
#consulteventepro .blockSearch .relation .contentBien {
  opacity: 0.5;
}
#consulteventepro .blockSearch .relation .footerAddBien {
  opacity: 1;
  width: 90%;
  background-color: #6FCB8B;
  border: 2px solid #5AAA72;
}
#consulteventepro .blockSearch .relation .footerAddBien p {
  padding: 22px;
  border-radius: 15px;
  color: white;
}
#consulteventepro .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
#consulteventepro .modal .backgroundModal .contentModal {
  position: relative;
  background: white;
  padding: 60px 10px;
  width: 95vw;
  height: 95vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
}
#consulteventepro .modal .backgroundModal .contentModal .close {
  position: absolute;
  height: 40px;
  width: 40px;
  margin: 0;
  top: 0;
  right: 0;
  cursor: pointer;
}
#consulteventepro .modal .backgroundModal .contentModal textarea {
  width: 90%;
  height: 160px;
  padding: 10px;
  border-color: #939393;
  border-radius: 15px;
  margin: 20px auto;
}
#consulteventepro .modal .backgroundModal .contentModal div {
  height: 50px;
  margin: 30px auto 0;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#consulteventepro .modal .backgroundModal .contentModal div a {
  text-decoration: none;
  background: #43a1db;
  color: white;
  margin: 0 10px;
  border-style: none;
  border-radius: 10px;
  padding: 10px;
  transition: 500ms;
  display: block;
  width: 105px;
  height: 40px;
}
#consulteventepro .modal .backgroundModal .contentModal div .warning {
  background-color: red;
}
#consulteventepro .formblock {
  margin: 50px 0;
}
#consulteventepro .formblock label {
  font-weight: 400;
  color: #2f2f2f;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
#consulteventepro .formblock input {
  padding-left: 10px;
  margin: 25px 0 0px;
  border-radius: 10px;
  height: 35px;
  width: 100%;
  border: 2px solid black;
}
#consulteventepro .formblock input[type=radio] {
  width: unset;
  margin: 0;
  width: 13px;
  height: 13px;
}
#consulteventepro .formblock textarea {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px;
  margin-top: 20px;
}
#consulteventepro .formblock .blockNbPiece {
  width: 550px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#consulteventepro .formblock .blockNbPiece .nbpiece {
  margin: 20px 10px 0px;
  border: 2px solid black;
  flex-wrap: wrap;
  border-radius: 5px;
  border-radius: 15px;
  padding: 5px 12px;
  transition: 500ms;
}
#consulteventepro .formblock .blockNbPiece .nbpiece:first-child {
  margin-left: 0;
}
#consulteventepro .formblock .blockNbPiece .nbpiece:hover {
  border-color: #46ce85;
  color: white;
}
#consulteventepro .formblock .blockNbPiece .nbpiece.selected {
  background: #46ce85;
  border-color: #33ad6c;
  color: white;
}
#consulteventepro .formblock.disflex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}
#consulteventepro .formblock.disflex .blockNbPiece {
  margin: 20px auto;
  flex-wrap: wrap;
  width: 75%;
  justify-content: center;
}
#consulteventepro .alertVolet {
  position: fixed;
  top: 100px;
  right: 0px;
  background: #33ad6c;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
#consulteventepro .alertVolet .ongletAlert {
  margin: 10px;
  margin-right: 0;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
}
#consulteventepro .blockAlert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
#consulteventepro .blockAlert .alertList.createAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: -45%;
  height: 440px;
  top: 55%;
  margin-top: -250px;
}
#consulteventepro .blockAlert .alertList.createAlert .alertSubmitForm {
  width: 100%;
}
#consulteventepro .blockAlert .alertList {
  width: 96%;
  height: 96%;
  margin: 10px auto 0;
  background: white;
  position: fixed;
  left: 50%;
  margin-left: -48%;
  overflow-x: scroll;
}
#consulteventepro .blockAlert .alertList .alertSubmitForm {
  display: flex;
  flex-direction: column;
}
#consulteventepro .blockAlert .alertList .alertSubmitForm .close {
  position: absolute;
  right: 0;
  top: 0;
}
#consulteventepro .blockAlert .alertList .alertSubmitForm input {
  width: 100%;
  height: 35px;
  padding-left: 10px;
  border: 1px solid #939393;
  border-radius: 15px;
  margin: 20px auto;
}
#consulteventepro .blockAlert .alertList .alertSubmitForm a {
  text-decoration: none;
  background: #43a1db;
  color: white;
  margin: 20px auto;
  border-style: none;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 500ms;
}
#consulteventepro .blockAlert .alertList .listeAlert {
  height: 500px;
  background: white;
}
#consulteventepro .blockAlert .alertList .listeAlert .close {
  position: absolute;
  top: 0;
  right: 0;
}
#consulteventepro .blockAlert .alertList .listeAlert .table {
  text-align: center;
}
#consulteventepro .blockAlert .alertList .listeAlert .table .tableHead {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}
#consulteventepro .blockAlert .alertList .listeAlert .table .tableHead div {
  width: 173px;
  word-break: break-word;
}
#consulteventepro .blockAlert .alertList .listeAlert .table .alertLine {
  display: flex;
  height: 80px;
  justify-content: space-around;
}
#consulteventepro .blockAlert .alertList .listeAlert .table .alertLine div {
  width: 173px;
  word-break: break-word;
}
@media only screen and (min-width: 375px) {
  #consulteventepro .submitForm a {
    width: 160px;
  }
  #consulteventepro .modal .backgroundModal .contentModal {
    width: 90vw;
    height: 550px;
  }
}
@media only screen and (min-width: 600px) {
  #consulteventepro .submitForm a {
    width: 160px;
  }
  #consulteventepro .modal .backgroundModal .contentModal {
    width: 90vw;
    height: 550px;
  }
  #consulteventepro .blockAlert .alertList.createAlert {
    height: 468px;
    top: 42%;
  }
}
@media only screen and (min-width: 800px) {
  #consulteventepro .form {
    margin: 35px 0 0;
    width: 100%;
  }
  #consulteventepro .form h2 {
    margin-bottom: 40px;
  }
  #consulteventepro .form .prix {
    justify-content: space-between;
    width: 100%;
  }
  #consulteventepro .form .prix input {
    width: 338px;
    margin: 0;
  }
  #consulteventepro .form .block {
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  #consulteventepro .form .block .btn.formbtn {
    width: 338px;
  }
  #consulteventepro .form .block .selectBlock {
    width: 338px;
  }
  #consulteventepro .form .block .selectBlock select {
    width: 100%;
  }
  #consulteventepro .blockSearch .bienBlock {
    width: 45%;
  }
  #consulteventepro .modal .backgroundModal .contentModal textarea {
    width: 338px;
  }
  #consulteventepro .alertVolet {
    width: 55px;
    height: 50px;
  }
  #consulteventepro .blockAlert .alertList {
    width: 660px;
    margin: 10px auto 0;
    background: white;
    position: fixed;
    left: 57%;
    margin-left: -45%;
    overflow-x: scroll;
  }
  #consulteventepro .blockAlert .alertList .alertSubmitForm input {
    width: 338px;
    height: 47px;
  }
}
@media only screen and (min-width: 960px) {
  #consulteventepro .form .prix {
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 1280px) {
  #consulteventepro .form {
    margin-top: 35px;
  }
  #consulteventepro .form h2 {
    margin-bottom: 50px;
  }
  #consulteventepro .form .prix {
    width: 730px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  #consulteventepro .form .prix input {
    padding-left: 10px;
    margin: 25px 0 0px;
    border-radius: 15px;
    height: 66px;
    width: 338px;
    border: 2px solid black;
  }
  #consulteventepro .form .prix .message {
    margin: 30px auto 0;
    color: red;
  }
  #consulteventepro .form .block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 730px;
    margin: 0 auto;
  }
  #consulteventepro .form .block .btn.formbtn {
    border: 2px solid #2f2f2f;
    width: 338px;
    border-radius: 15px;
    margin-bottom: 25px;
    text-align: center;
    cursor: pointer;
  }
  #consulteventepro .form .block .btn.formbtn:hover {
    border-color: #46ce85;
  }
  #consulteventepro .form .block .btn.formbtn.selected {
    background: #46ce85;
    border-color: #33ad6c;
    color: white;
  }
  #consulteventepro .form .block .selectBlock select {
    border: 2px solid #2f2f2f;
    padding: 10px 10px;
    width: 338px;
    border-radius: 15px;
    margin-bottom: 25px;
    text-align: center;
    cursor: pointer;
  }
  #consulteventepro .form .block .selectBlock:nth-child(2n+1) {
    margin-right: 45px;
  }
  #consulteventepro .submitForm {
    text-align: center;
    margin-top: 40px;
  }
  #consulteventepro .submitForm a {
    background: #43a1db;
    color: white;
    border-radius: 15px;
    border: none;
    padding: 15px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: 500ms;
    width: 257px;
    height: 50px;
    margin-right: 20px;
    transition: 500ms;
  }
  #consulteventepro .submitForm a:nth-child(0n+2) {
    margin-right: 0;
    margin-left: 20px;
  }
  #consulteventepro .submitForm a span {
    display: inline;
    position: relative;
    top: 1px;
    left: 2px;
    transition: left 500ms;
  }
  #consulteventepro .submitForm a:hover {
    background: #3186ba;
  }
  #consulteventepro .submitForm a:hover span {
    left: 7px;
  }
  #consulteventepro .blockSearch {
    width: 1200px;
    margin: 0 auto 100px;
  }
  #consulteventepro .blockSearch .bienBlock {
    margin: 50px 0 0 35px;
    width: 30%;
    min-height: 650px;
  }
  #consulteventepro .blockSearch .bienBlock:nth-child(3n+1) {
    margin-left: 25px;
  }
  #consulteventepro .blockSearch .bienBlock .contentBien {
    margin-top: 40px;
  }
  #consulteventepro .blockSearch .bienBlock .contentBien .blockRecap {
    display: flex;
    margin: 20px 13px;
    text-align: start;
  }
  #consulteventepro .blockSearch .bienBlock .contentBien .blockRecap p {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
    width: 50%;
  }
  #consulteventepro .blockSearch .bienBlock .contentBien .blockRecap p:first-child {
    font-weight: 600;
  }
  #consulteventepro .modal .backgroundModal .contentModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    height: 500px;
  }
  #consulteventepro .formblock {
    margin: 50px 0;
  }
  #consulteventepro .formblock label {
    color: #2f2f2f;
  }
  #consulteventepro .formblock input {
    padding-left: 10px;
    margin: 25px 0 0px;
    border-radius: 10px;
    height: 35px;
    width: 100%;
    border: 2px solid black;
  }
  #consulteventepro .formblock input[type=radio] {
    width: unset;
    margin: 0;
    width: 13px;
    height: 13px;
  }
  #consulteventepro .formblock textarea {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    border: 2px solid black;
    padding: 10px;
    margin-top: 20px;
  }
  #consulteventepro .formblock .blockNbPiece {
    width: 550px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  #consulteventepro .formblock .blockNbPiece .nbpiece {
    margin-left: 25px;
    border: 2px solid black;
    flex-wrap: wrap;
    transition: 500ms;
    cursor: pointer;
  }
  #consulteventepro .formblock .blockNbPiece .nbpiece:first-child {
    margin-left: 0;
  }
  #consulteventepro .formblock .blockNbPiece .nbpiece:hover {
    border-color: #46ce85;
    color: white;
  }
  #consulteventepro .formblock .blockNbPiece .nbpiece.selected {
    background: #46ce85;
    border-color: #33ad6c;
    color: white;
  }
  #consulteventepro .formblock.disflex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
  }
  #consulteventepro .formblock.disflex .blockNbPiece {
    margin: 20px auto;
    justify-content: center;
  }
  #consulteventepro .blockAlert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #consulteventepro .blockAlert .alertList.createAlert {
    width: 900px;
    height: 500px;
    margin: 200px auto 0;
    background: white;
    padding: 20px;
    position: fixed;
    margin-left: -450px;
    top: 50%;
    margin-top: -250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: 2px;
  }
  #consulteventepro .blockAlert .alertList {
    margin: 200px auto 0;
    background: white;
    position: fixed;
    height: 630px;
    left: 73%;
    margin-left: -45%;
    top: 48%;
    margin-top: -22%;
    -ms-overflow-style: none;
    scrollbar-width: 2px;
  }
  #consulteventepro .blockAlert .alertList .alertSubmitForm {
    display: flex;
    flex-direction: column;
  }
  #consulteventepro .blockAlert .alertList .alertSubmitForm input {
    width: 338px;
    height: 47px;
    padding-left: 10px;
    border-color: #939393;
    border-radius: 15px;
    margin: 20px auto;
  }
  #consulteventepro .blockAlert .alertList .listeAlert {
    height: 500px;
    background: white;
    margin: 20px auto 0;
  }
  #consulteventepro .blockAlert .alertList .listeAlert table .alertLine {
    height: 80px;
  }
}
@media only screen and (min-width: 1600px) {
  #consulteventepro .alertVolet {
    width: 155px;
    height: 68px;
  }
  #consulteventepro .alertVolet .ongletAlert {
    margin: 25px;
    font-size: 16px;
  }
}
.alert {
  width: 70%;
  margin: 50px auto 0;
  color: white;
  border-radius: 3px;
  text-align: center;
  padding: 10px 0px;
  font-family: "Open Sans", sans-serif;
  border-radius: 50px;
  margin-bottom: 20px;
}

.alert-success {
  background: #46ce85;
}

.alert-warning {
  background: #e6254b;
}

@media only screen and (min-width: 1280px) {
  .alert {
    width: 50%;
  }
}
.abonnement .blockcontent .headerPrix {
  display: flex;
  flex-direction: column;
}
.abonnement .blockcontent .headerPrix .left {
  border-radius: 15px;
  width: 100%;
  height: 100px;
  padding: 15px;
  text-align: center;
  box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.16);
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.abonnement .blockcontent .headerPrix .left p {
  font-size: 16px;
  font-weight: 400;
}
.abonnement .blockcontent .headerPrix .right {
  border-radius: 15px;
  width: 100%;
  height: 100px;
  padding: 15px;
  text-align: center;
  box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.16);
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.abonnement .blockcontent .headerPrix .right p {
  font-size: 16px;
  font-weight: 400;
}
.abonnement #selectionDepartement h2 {
  font-size: 20px;
  font-weight: 600;
}
.abonnement #selectionDepartement .from-control select {
  height: 66px;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader {
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockDepart {
  border: none !important;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockDepart p {
  font-size: 20px;
  font-weight: 600;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockPrix {
  border: none !important;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockPrix p {
  font-size: 20px;
  font-weight: 600;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody {
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div {
  justify-content: space-between;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockDepart {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockPrix {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockPrix a {
  display: block;
  width: 24px !important;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementFooter {
  justify-content: flex-end;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementFooter .blockDepart {
  border: none !important;
  font-size: 20px;
  font-weight: 600;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementFooter .blockPrix {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abonnement #selectionDepartement .listeDepartementSelect .listeDepartementFooter .blockPrix .hiddenBlockReplace {
  display: block;
  width: 24px !important;
}
.abonnement #selectionDepartement .listeDepartementSelect .openPayment a span {
  display: none;
}
.abonnement #selectionCode h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.abonnement #selectionCode .from-control select {
  align-items: center;
  padding: 10px 25px;
  width: 300px;
  text-align: center;
  margin-bottom: 35px;
  background: none;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  height: 66px;
}
.abonnement #selectionCode .from-control a {
  width: 75px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abonnement #selectionCode .from-control a span {
  position: relative;
  left: 5px;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader .blockDepart {
  border: none !important;
  padding: 10px 0;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader .blockDepart p {
  font-size: 14px;
  font-weight: 600;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader .blockPrix {
  border: none !important;
  padding: 10px 0;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader .blockPrix p {
  font-size: 14px;
  font-weight: 600;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody {
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div {
  display: flex;
  justify-content: space-between;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockDepart {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 10px 0;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockPrix {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 10px 0;
  text-align: center;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockPrix p {
  width: 100%;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockPrix a {
  display: block;
  width: 24px !important;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter {
  display: flex;
  justify-content: flex-end;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter .blockDepart {
  border: none !important;
  font-size: 14px;
  font-weight: 600;
  width: 33%;
  padding: 10px 0;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter .blockPrix {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 10px 0;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter .blockPrix p {
  text-align: center;
}
.abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter .blockPrix .hiddenBlockReplace {
  display: block;
  width: 24px !important;
}
.abonnement #selectionCode .listeDepartementSelect .openPayment a {
  display: block;
  text-decoration: none;
  background: #43a1db;
  color: white;
  border-style: none;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 500ms;
  width: 278px;
  margin: 0 auto;
  border: 1px solid #43a1db !important;
}
.abonnement #selectionCode .listeDepartementSelect .openPayment a span {
  display: none;
}
.abonnement #paimentBlock input {
  width: 100%;
  border: 1px solid #939393;
  border-radius: 15px;
  padding: 15px;
}
.abonnement #subcriptionCancel .blockfiltre {
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #subcriptionCancel .tableBody .tableLine {
  border-bottom: 1px solid #E5E5E5;
}
.abonnement #subcriptionCancel .tableBody .tableLine .trBody {
  border: none !important;
}
.abonnement #subcriptionCancel .tableBody .tableLine .trBody:nth-child(2) {
  width: 70% !important;
  text-align: left;
}
.abonnement #subcriptionCancel .tableBody .tableLine .trBody:nth-child(3) {
  width: 20% !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.abonnement #subcriptionCancel .tableBody .tableLine .trBody:nth-child(3) a {
  display: block;
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 768px) {
  .abonnement .blockcontent .headerPrix {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .abonnement .blockcontent .headerPrix .left {
    width: 45%;
  }
  .abonnement .blockcontent .headerPrix .right {
    width: 45%;
  }
  .abonnement #selectionDepartement {
    width: 80%;
    margin: 0 auto;
  }
  .abonnement #selectionCode .from-control select {
    display: block;
    margin: 0 auto 35px;
  }
  .abonnement #subcriptionCancel {
    width: 80%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 960px) {
  .abonnement .blockcontent {
    width: 910px !important;
    margin: 0 auto 70px !important;
  }
  .abonnement .blockcontent .headerPrix .left {
    width: 425px;
  }
  .abonnement .blockcontent .headerPrix .right {
    width: 425px;
  }
  .abonnement form {
    width: 100% !important;
  }
  .abonnement #selectionDepartement {
    width: 910px !important;
    margin: 0 auto 70px !important;
  }
  .abonnement #selectionDepartement .from-control select.departementSelect {
    display: block;
    width: 261px !important;
    margin: 20px auto 25px !important;
  }
  .abonnement #selectionCode {
    width: 910px !important;
    margin: 0 auto 70px !important;
  }
  .abonnement #selectionCode .listeDepartementSelect .openPayment {
    margin-top: 35px;
  }
  .abonnement #paimentBlock {
    width: 910px !important;
    margin: 0 auto 70px !important;
  }
  .abonnement #subcriptionCancel {
    width: 910px !important;
    margin: 20px auto 70px !important;
  }
  .abonnement #subcriptionCancel .blockfiltre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    border: none;
  }
  .abonnement #subcriptionCancel .tableHeader {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  .abonnement #subcriptionCancel .tableHeader .trHeader {
    text-align: center;
    width: 33%;
  }
  .abonnement #subcriptionCancel .tableHeader .trHeader:nth-child(3) {
    width: 15% !important;
  }
  .abonnement #subcriptionCancel .tableBody {
    width: 100% !important;
    margin: 0 !important;
  }
  .abonnement #subcriptionCancel .tableBody .tableLine {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .abonnement #subcriptionCancel .tableBody .tableLine .trBody {
    width: 33% !important;
  }
  .abonnement #subcriptionCancel .tableBody .tableLine .trBody:nth-child(2) {
    width: 33% !important;
    text-align: center;
  }
  .abonnement #subcriptionCancel .tableBody .tableLine .trBody:nth-child(3) {
    width: 15% !important;
    justify-content: center;
  }
}
@media only screen and (min-width: 1280px) {
  .abonnement #selectionDepartement .from-control {
    flex-direction: column;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockDepart {
    width: auto !important;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockDepart p {
    text-align: left;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementHeader .blockPrix {
    width: 15% !important;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockDepart {
    width: auto !important;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockDepart p {
    text-align: left;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementBody div .blockPrix {
    width: 15% !important;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .listeDepartementFooter .blockPrix {
    width: 15% !important;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .openPayment a {
    width: 293px !important;
    transition: 500ms;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .openPayment a span {
    display: inline;
    position: relative;
    top: 1px;
    transition: left 500ms;
  }
  .abonnement #selectionDepartement .listeDepartementSelect .openPayment a:hover span {
    left: 5px;
  }
  .abonnement #selectionCode .from-control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .abonnement #selectionCode .from-control h2 {
    display: block;
    width: 100%;
  }
  .abonnement #selectionCode .from-control select {
    margin: 0 5px 0 0;
    width: 261px;
  }
  .abonnement #selectionCode .listeDepartementSelect {
    width: 100%;
  }
  .abonnement #selectionCode .listeDepartementSelect .listeDepartementHeader .blockPrix {
    width: 15%;
  }
  .abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockDepart {
    width: auto;
  }
  .abonnement #selectionCode .listeDepartementSelect .listeDepartementBody div .blockPrix {
    width: 15%;
  }
  .abonnement #selectionCode .listeDepartementSelect .listeDepartementFooter .blockPrix {
    width: 15%;
  }
  .abonnement #subcriptionCancel .tableHeader {
    width: 100% !important;
    justify-content: space-between !important;
  }
  .abonnement #subcriptionCancel .tableHeader .trHeader {
    border: none !important;
    text-align: center;
    width: 33% !important;
  }
  .abonnement #subcriptionCancel .tableHeader .trHeader:nth-child(3) {
    width: 15% !important;
  }
  .abonnement #subcriptionCancel .tableBody .tableLine {
    width: 100% !important;
    justify-content: space-between !important;
  }
}