#consulteventepro{
    .form{
        margin: 35px auto;
        width: 90%;
        .addFilter{
            text-align: center;
            cursor: pointer;
            font-size: 18px;
            font-weight: 700;
        }
        h2{
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 600;
            color:#2F2F2F;
            a{
                float: right;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
        .prix{
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            input{
                padding-left: 10px;
                margin: 25px 0 0px;
                border-radius: 10px;
                height: 35px;
                width: 100%;
                border: 2px solid black;
                height: 66px;
                width: 280px;
                font-size: 14px;
            }
            .message{
                margin: 30px auto 0;
                color: red
            }
        }
        .block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
            .btn.formbtn{
                border:2px solid #2f2f2f;
                padding: 20px 10px;
                width: 100%;
                border-radius: 15px;
                margin-bottom: 25px;
                text-align: center;
                transition: 200ms;
                height: 66px;
                width: 280px;
                font-size: 14px;
                &:hover {
                    border-color: #46ce85;
                }

                &.selected {
                    background: #46ce85;
                    border-color: #33ad6c;
                    color: white;
                }
            }
            .selectBlock{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                select{
                   text-align: center;
                    border:2px solid #2f2f2f;
                    padding: 10px 10px;
                    width: 100%;
                    border-radius: 15px;
                    margin-bottom: 25px;
                    height: 66px;
                    width: 280px;
                    font-size: 14px;
                }
            }
        }
    }
    .submitForm{
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        a{
            background:#43a1db;
            color: white;
            border-radius: 15px;
            border: none;
            padding: 15px;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            transition: 500ms;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            margin-right: 10px;
            &:nth-child(0n+2){
                margin-left: 10px;
                margin-right: 0;
            }
            &:hover{
                background: #3186ba;
            }
        }
        span {
            display: none;
        }
    }
    .blockSearch{
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        .nothingSearch{
            width: 100%;
            text-align: center;
            margin-top: 50px;
        }
        .search{
            margin-top: 40px;
            color: red;
            text-align: center;
            width: 102%;
        }
        .bienBlock{
            margin: 50px auto;
            width: 100%;
            min-height: 680px ;
            text-align: center;
            position: relative;
            border-radius: 15px;
            .footerAddBien{
                position: absolute;
                bottom: 0px;
                width: 90%;
                left: 0;
                right: 0;
                background: #ffffff;
                height: 68px;
                border-radius: 13px;
                margin: 0 auto 45px;
                a{
                    display: block;
                    border: none;
                    padding: 13px;
                    color: #2F2F2F;
                    transition: 500ms;
                    &:hover{
                        transition: 500ms;
                        border-color: #46CE85;
                    }
                }
            }
            .dateformat{
                position: relative;
                top: -20px;
                width: 80%;
                margin: 0 auto;
                p{
                    text-align: right;
                    font-size: 14px;
                    font-weight: 500;
                    color: #939393;
                }
            }
            .blockRecap{
                margin: 20px 13px;
                text-align: center;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 0;
                    &:first-child{
                        font-weight: 600;
                    }
                }
            }
        }
        .relation{
            border-color: grey;
            .headerBien{
                opacity: 0.5;
            }
            .contentBien{
                opacity: 0.5;
            }
            .footerAddBien{
                opacity: 1;
                width: 90%;
                background-color: #6FCB8B;
                border: 2px solid #5AAA72;
                p{
                    padding: 22px;
                    border-radius: 15px;
                    color: white;
                    &:hover{
                    }
                }
            }
        }
    }
    .modal{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        .backgroundModal{
            .contentModal{
                position: relative;
                background: white;
                padding: 60px 10px;
                width: 95vw;
                height: 95vh;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 15px;
                .close{
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    margin: 0;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }
                textarea{
                    width: 90%;
                    height: 160px;
                    padding: 10px;
                    border-color: #939393;
                    border-radius: 15px;
                    margin: 20px auto;
                }
                div{
                    height: 50px;
                    margin: 30px auto 0;
                    width: 90%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        text-decoration: none;
                        background: #43a1db;
                        color: white;
                        margin: 0 10px;
                        border-style: none;
                        border-radius: 10px;
                        padding: 10px;
                        transition: 500ms;
                        display: block;
                        width: 105px;
                        height: 40px;
                    }
                    .warning{
                        background-color: red;
                    }
                }
            }
        }
    }
    .formblock{
        margin: 50px 0;
        label{
            font-weight: 400;
            color: #2f2f2f;
            width: 100%;
            font-size: 20px;
            font-weight: 600;
        }
        input{
            padding-left: 10px;
            margin: 25px 0 0px;
            border-radius: 10px;
            height: 35px;
            width: 100%;
            border: 2px solid black;
        }
        input[type="radio"]{
            width: unset;
            margin: 0;
            width: 13px;
            height: 13px;
        }
        textarea{
            width: 100%;
            height: 250px;
            border-radius: 10px;
            border: 2px solid black;
            padding: 10px;
            margin-top: 20px;
        }
        .blockNbPiece{
            width: 550px;
            margin: 0 auto;
            height : 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            .nbpiece{
                margin: 20px 10px 0px;
                border: 2px solid black;
                flex-wrap: wrap;
                border-radius: 5px;
                border-radius: 15px;
                padding: 5px 12px;
                transition: 500ms;
                &:first-child{
                    margin-left: 0;
                }
                &:hover{
                    border-color: #46ce85;
                    color: white;
                }
                &.selected{
                    background: #46ce85;
                    border-color: #33ad6c;
                    color: white;
                }
            }
        }
        &.disflex{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            text-align: center;
            .blockNbPiece{
                margin: 20px auto;
                flex-wrap: wrap;
                width: 75%;
                justify-content: center;
            }
        }
    }
    .alertVolet{
        position: fixed;
        top: 100px;
        right: 0px;
        background: #33ad6c;
        width: 50px;
        height: 50px;
        border-radius: 15px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        justify-content: center;
        align-items: center;
        z-index: 999;
        .ongletAlert{
            margin: 10px;
            margin-right: 0;
            font-size: 12px;
            color: white;
            text-align: center;
            cursor: pointer;
        }
        &.open{

        }
    }
    .blockAlert{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 999;
        .alertList.createAlert{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin-left: -45%;
            height: 440px;
            top: 55%;
            margin-top: -250px;
            .alertSubmitForm{
                width: 100%;
            }
        }
        .alertList{
            width: 96%;
            height: 96%;
            margin: 10px auto 0;
            background: white;
            position: fixed;
            left: 50%;
            margin-left: -48%;
            overflow-x: scroll;
            .alertSubmitForm{
                display: flex;
                flex-direction: column;
                .close{
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                input{
                    width: 100%;
                    height: 35px;
                    padding-left: 10px;
                    border: 1px solid #939393;
                    border-radius: 15px;
                    margin: 20px auto;
                }
                a{
                    text-decoration: none;
                    background: #43a1db;
                    color: white;
                    margin: 20px auto;
                    border-style: none;
                    border-radius: 10px;
                    padding: 10px 20px;
                    transition: 500ms;
                }
            }
            .listeAlert{
                height: 500px;
                background: white;
                .close{
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .table{
                    text-align: center;
                    .tableHead{
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 30px;
                        div{
                            width: 173px;
                            word-break: break-word;
                        }
                    }
                    .alertLine{
                        display: flex;
                        height: 80px;
                        justify-content: space-around;
                        div{
                            width: 173px;
                            word-break: break-word;
                            &:last-child{
                                a{
                                    &:last-child{
                                    }
                                    &:hover {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 375px) {
    #consulteventepro{
        .form{
            h2{
            }
            .prix{
                input{
                }
                .message{
                }
            }
            .block{
                .btn.formbtn{
                    &:hover {
                    }

                    &.selected {
                    }
                }
                .selectBlock{
                    select{
                    }
                }
            }
        }
        .submitForm{
            a{
                width: 160px;
                &:last-child{
                }
                &:hover{
                }
            }
        }
        .blockSearch{
            .bienBlock{
                &:nth-child(3n+1){
                }
                .footerAddBien{
                    a{
                        &:hover{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
            .relation{
                .headerBien{
                }
                .contentBien{
                }
                .footerAddBien{
                    p{
                        &:hover{
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    width: 90vw;
                    height: 550px;
                    textarea{
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            label{
            }
            input{
            }
            input[type="radio"]{
            }
            textarea{
            }
            .blockNbPiece{
                .nbpiece{
                    &:first-child{
                    }
                    &:hover{
                    }
                    &.selected{
                    }
                }
            }
            &.disflex{
                .blockNbPiece{
                }
            }
        }
        .alertVolet{
            .ongletAlert{
            }
            &.open{

            }
        }
        .blockAlert{
            .alertList{
                .alertSubmitForm{
                    .close{
                    }
                    input{
                    }
                    a{
                    }
                }
                .listeAlert{
                    .close{
                    }
                    .table{
                        .tableHead{
                            div{
                            }
                        }
                        .alertLine{
                            div{
                                &:last-child{
                                    a{
                                        &:last-child{
                                        }
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    #consulteventepro{
        .form{
            h2{
            }
            .prix{
                input{
                }
                .message{
                }
            }
            .block{
                .btn.formbtn{
                    &:hover {
                    }

                    &.selected {
                    }
                }
                .selectBlock{
                    select{
                    }
                }
            }
        }
        .submitForm{
            a{
                width: 160px;
                &:last-child{
                }
                &:hover{
                }
            }
        }
        .blockSearch{
            .bienBlock{
                &:nth-child(3n+1){
                }
                .footerAddBien{
                    a{
                        &:hover{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
            .relation{
                .headerBien{
                }
                .contentBien{
                }
                .footerAddBien{
                    p{
                        &:hover{
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    width: 90vw;
                    height: 550px;
                    textarea{
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            label{
            }
            input{
            }
            input[type="radio"]{
            }
            textarea{
            }
            .blockNbPiece{
                .nbpiece{
                    &:first-child{
                    }
                    &:hover{
                    }
                    &.selected{
                    }
                }
            }
            &.disflex{
                .blockNbPiece{
                }
            }
        }
        .alertVolet{
            .ongletAlert{
            }
            &.open{

            }
        }
        .blockAlert{
            .alertList.createAlert {
                height: 468px;
                top: 42%;
                .alertSubmitForm{
                    .close{
                    }
                    input{
                    }
                    a{
                    }
                }
                .listeAlert{
                    .close{
                    }
                    .table{
                        .tableHead{
                            div{
                            }
                        }
                        .alertLine{
                            div{
                                &:last-child{
                                    a{
                                        &:last-child{
                                        }
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 800px) {
    #consulteventepro{
        .form{
            margin: 35px 0 0;
            width: 100%;
            h2{
                margin-bottom: 40px;
            }
            .prix{
                justify-content: space-between;
                width: 100%;
                input{
                    width: 338px;
                    margin: 0;
                }
                .message{
                }
            }
            .block{
                flex-direction: row;
                margin-top: 20px;
                width: 100%;
                justify-content: space-between;
                .btn.formbtn{
                    width: 338px;
                    &:hover {
                    }
                    &.selected {
                    }
                }
                .selectBlock{
                    width: 338px;
                    select{
                        width: 100%;
                    }
                }
            }
        }
        .submitForm{
            a{
                &:hover{
                }
            }
        }
        .blockSearch{
            .bienBlock{
                width: 45%;
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    textarea{
                        width: 338px;
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            label{
            }
            input{
            }
            input[type="radio"]{
            }
            textarea{
            }
            .blockNbPiece{
                .nbpiece{
                    &:first-child{
                    }
                    &:hover{
                    }
                    &.selected{
                    }
                }
            }
            &.disflex{
                .blockNbPiece{
                }
            }
        }
        .alertVolet{
            width: 55px;
            height: 50px;
            .ongletAlert{
            }
            &.open{

            }
        }
        .blockAlert{
            .alertList{
                width: 660px;

                margin: 10px auto 0;
                background: white;
                position: fixed;
                left: 57%;
                margin-left: -45%;
                overflow-x: scroll;
                .alertSubmitForm{
                    input{
                        width: 338px;
                        height: 47px;
                    }
                    a{
                    }
                }
                .listeAlert{
                    table{
                        .alertLine{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    #consulteventepro{
        .form{
            h2{
            }
            .prix{
                justify-content: space-evenly;
                input{
                }
                .message{
                }
            }
            .block{
                .btn.formbtn{
                    &:hover {
                    }
                    &.selected {
                    }
                }
                .selectBlock{
                    select{
                    }
                }
            }
        }
        .submitForm{
            a{
                &:hover{
                }
            }
        }
        .blockSearch{
            .bienBlock{
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    textarea{
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            label{
            }
            input{
            }
            input[type="radio"]{
            }
            textarea{
            }
            .blockNbPiece{
                .nbpiece{
                    &:first-child{
                    }
                    &:hover{
                    }
                    &.selected{
                    }
                }
            }
            &.disflex{
                .blockNbPiece{
                }
            }
        }
        .alertVolet{
            .ongletAlert{
            }
            &.open{

            }
        }
        .blockAlert{
            .alertList{
                .alertSubmitForm{
                    input{
                    }
                    a{
                    }
                }
                .listeAlert{
                    table{
                        .alertLine{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    #consulteventepro{
        .form{
            margin-top: 35px;
            h2{
                margin-bottom: 50px;
            }
            .prix{
                width: 730px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                input{
                    padding-left: 10px;
                    margin: 25px 0 0px;
                    border-radius: 15px;
                    height: 66px;
                    width: 338px;
                    border: 2px solid black;
                }
                .message{
                    margin: 30px auto 0;
                    color: red
                }
            }
            .block{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 730px;
                margin: 0 auto;
                .btn.formbtn{
                    border:2px solid #2f2f2f;
                    width: 338px;
                    border-radius: 15px;
                    margin-bottom: 25px;
                    text-align: center;
                    cursor: pointer;
                    &:hover {
                        border-color: #46ce85;
                    }

                    &.selected {
                        background: #46ce85;
                        border-color: #33ad6c;
                        color: white;
                    }
                }
                .selectBlock{
                    select{
                        border:2px solid #2f2f2f;
                        padding: 10px 10px;
                        width: 338px;
                        border-radius: 15px;
                        margin-bottom: 25px;
                        text-align: center;
                        cursor: pointer;
                    }
                    &:nth-child(2n+1){
                        margin-right: 45px;
                    }
                }
            }
        }
        .submitForm{
            text-align: center;
            margin-top: 40px;
            a{
                background:#43a1db;
                color: white;
                border-radius: 15px;
                border: none;
                padding: 15px;
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
                transition: 500ms;
                width: 257px;
                height: 50px;
                margin-right: 20px;
                transition: 500ms;
                &:nth-child(0n+2){
                    margin-right: 0;
                    margin-left: 20px;
                }
                span{
                    display: inline;
                    position: relative;
                    top: 1px;
                    left: 2px;
                    transition: left 500ms;
                }
                &:hover{
                    background: #3186ba;
                    span{
                        left: 7px;
                    }
                }
            }
        }
        .blockSearch{
            width: 1200px;
            margin: 0 auto 100px;
            .bienBlock{
                margin: 50px 0 0 35px;
                width: 30%;
                min-height: 650px;
                &:nth-child(3n+1) {
                    margin-left: 25px;
                }
                .contentBien {
                    margin-top: 40px;
                    .blockRecap{
                        display: flex;
                        margin: 20px 13px;
                        text-align: start;
                        p{
                            font-size: 14px;
                            font-weight: 500;
                            margin-left: 5px;
                            width: 50%;
                            &:first-child{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 900px;
                    height: 500px;
                    textarea{
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            margin: 50px 0;
            label{
                color: #2f2f2f;
            }
            input{
                padding-left: 10px;
                margin: 25px 0 0px;
                border-radius: 10px;
                height: 35px;
                width: 100%;
                border: 2px solid black;
            }
            input[type="radio"]{
                width: unset;
                margin: 0;
                width: 13px;
                height: 13px;
            }
            textarea{
                width: 100%;
                height: 250px;
                border-radius: 10px;
                border: 2px solid black;
                padding: 10px;
                margin-top: 20px;
            }
            .blockNbPiece{
                width: 550px;
                margin: 0 auto;
                height : 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                .nbpiece{
                    margin-left: 25px;
                    border: 2px solid black;
                    flex-wrap: wrap;
                    transition: 500ms;
                    cursor: pointer;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:hover{
                        border-color: #46ce85;
                        color: white;
                    }
                    &.selected{
                        background: #46ce85;
                        border-color: #33ad6c;
                        color: white;
                    }
                }
            }
            &.disflex{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                text-align: center;
                .blockNbPiece{
                    margin: 20px auto;
                    justify-content: center;
                }
            }
        }
        .alertVolet{
            .ongletAlert{
            }
            &.open{

            }
        }
        .blockAlert{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .alertList.createAlert{
                width: 900px;
                height: 500px;
                margin: 200px auto 0;
                background: white;
                padding: 20px;
                position: fixed;
                margin-left: -450px;
                top: 50%;
                margin-top: -250px;
                display: flex;
                justify-content: center;
                align-items: center;
                -ms-overflow-style: none;
                scrollbar-width: 2px;
            }
            .alertList{
                margin: 200px auto 0;
                background: white;
                position: fixed;
                height: 630px;
                left: 73%;
                margin-left: -45%;
                top: 48%;
                margin-top: -22%;
                -ms-overflow-style: none;
                scrollbar-width: 2px;
                .alertSubmitForm{
                    display: flex;
                    flex-direction: column;
                    input{
                        width: 338px;
                        height: 47px;
                        padding-left: 10px;
                        border-color: #939393;
                        border-radius: 15px;
                        margin: 20px auto;
                    }
                    a{
                    }
                }
                .listeAlert{
                    height: 500px;
                    background: white;
                    margin: 20px auto 0;
                    table{
                        .alertLine{
                            height: 80px;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1600px) {
    #consulteventepro{
        .form{
            h2{
            }
            .prix{
                input{
                }
                .message{
                }
            }
            .block{
                .btn.formbtn{
                    &:hover {
                    }

                    &.selected {
                    }
                }
                .selectBlock{
                    select{
                    }
                }
            }
        }
        .submitForm{
            a{
                &:last-child{
                }
                &:hover{
                }
            }
        }
        .blockSearch{
            .bienBlock{
                &:nth-child(3n+1){
                }
                .footerAddBien{
                    a{
                        &:hover{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
            .relation{
                .headerBien{
                }
                .contentBien{
                }
                .footerAddBien{
                    p{
                        &:hover{
                        }
                    }
                }
            }
        }
        .modal{
            .backgroundModal{
                .contentModal{
                    textarea{
                    }
                    div{
                        a{
                        }
                    }
                }
            }
        }
        .formblock{
            label{
            }
            input{
            }
            input[type="radio"]{
            }
            textarea{
            }
            .blockNbPiece{
                .nbpiece{
                    &:first-child{
                    }
                    &:hover{
                    }
                    &.selected{
                    }
                }
            }
            &.disflex{
                .blockNbPiece{
                }
            }
        }
        .alertVolet{
            width: 155px;
            height: 68px;
            .ongletAlert{
                margin: 25px;
                font-size: 16px;
            }
            &.open{

            }
        }
        .blockAlert{
            .alertList{
                .alertSubmitForm{
                    .close{
                    }
                    input{
                    }
                    a{
                    }
                }
                .listeAlert{
                    .close{
                    }
                    .table{
                        .tableHead{
                            div{
                            }
                        }
                        .alertLine{
                            div{
                                &:last-child{
                                    a{
                                        &:last-child{
                                        }
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
