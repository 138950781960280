.formProConnexion{
    width: 95%;
    margin: 100px auto;
    .blockleft{
        margin-bottom: 50px;
        text-align: center;
        h2{
            font-size: 28px;
            margin-bottom: 15px;
            font-weight: 500;
        }
        input[type='text'],
        input[type="email"],
        input[type="password"]{
            width: 100%;
            height: 47px;
            margin-bottom: 25px;
            border-radius: 15px;
            border: 1px solid #939393;
            padding-left: 10px;
            font-size: 16px;
        }
        button[type="submit"]{
            width: 100%;
            height: 80px;
            font-size: 28px;
            font-weight: 800;
        }
        .form-check{
            margin-bottom: 10px;
        }
        .btn.btn-link{
            display: block;
            width: 100%;
            margin: 15px 0;
            color: #43a1db;
            font-size: 16px;
            font-weight: 500;
        }
        .row{
            .col-md-6{
                .invalid-feedback{
                    color: red;
                }
            }
        }
    }
    .blockright{
        text-align: center;
        h2{
            font-size: 28px;
            margin-bottom: 15px;
            font-weight: 500;
        }
        input[type="text"],
        input[type="email"],
        input[type="password"]{
            width: 100%;
            height: 47px;
            margin-bottom: 25px;
            border-radius: 15px;
            border: 1px solid #939393;
            padding-left: 10px;
            font-size: 16px;
        }
        button[type="submit"]{
            width: 100%;
            height: 80px;
            font-size: 28px;
            font-weight: 800;
        }
    }
}
@media only screen and (min-width: 960px) {
    .formProConnexion{
        width: 860px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .blockleft{
            width: 45%;
            h2{
            }
            input[type="email"],
            input[type="password"]{
            }
            button[type="submit"]{
            }
            .form-check{
            }
            .btn.btn-link{
            }
        }
        .blockright{
            width: 45%;
            h2{
            }
            input[type="text"],
            input[type="email"],
            input[type="password"]{
            }
            button[type="submit"]{
            }
        }
        .row{
            .col-md-6{
                .invalid-feedback{
                    color: red;
                }
            }
        }
    }
}
