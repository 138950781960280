.alert{
    width: 70%;
    margin: 50px auto 0;
    color: white;
    border-radius: 3px;
    text-align: center;
    padding: 10px 0px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
    margin-bottom: 20px;
}
.alert-success{
    background: #46ce85;
}
.alert-warning{
    background: #e6254b;
}
@media only screen and (min-width: 1280px) {
    .alert{
        width: 50%;
    }
    .alert-success{
    }
    .alert-warning{
    }
}