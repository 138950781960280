#btn2{
    a{
        font-size: 10px;
    }
}
#deleteVideo2{
    background: url('../img/Icon feather-trash-2@2x.png') top left no-repeat rgba(0,0,0,0);
    background-size: contain;
    color: rgba(0,0,0,0);
    width: 20px;
    height: 25px;
    cursor: pointer;
    margin-left: 15px;
    position: relative;
    z-index: 10000;
}
.btn.btn-primary{
    display: block;
    background: #43a1db;
    color: white;
    border-radius: 15px;
    border: none;
    padding: 15px 45px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: 500ms;
    width: 280px;
    text-align: center;
    height: 50px;
    margin: 0 auto 20px;
}
.blocknavigation{
    width: 95%;
    margin: 75px auto 50px;
    display: flex;
    justify-content: center;
    nav{
        ul{
            list-style: none;
            li{
                border-bottom: 1px solid #939393 ;
                width: 300px;
                text-align: center;
                padding: 10px 0;
                margin: 0 auto;
                &:last-child{
                    border-bottom: none;
                }
                a{
                    font-size: 16px;
                    font-weight: 400;
                    color: #939393;
                }
                .currentLink{
                    color: #2f2f2f;
                }
            }
        }
    }
}
.blockcontent{
    width: 95%;
    margin: 0 auto 30px;
    h1{
        text-align: center;
        margin-bottom: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #939393;
    }
    h2{
        font-size: 16px;
        font-weight: 400;
        color: #939393;
        text-align: center;
        margin-bottom: 10px;
    }
    h3{
        font-size: 20px;
        font-weight: 600;
        color: #2f2f2f;
        margin: 20px 0;
        text-align: center;
    }
    .informations{
        display: block;
        width: 100%;
        text-align: center;
        margin: 0 auto 15px;
    }
    .formAlign{
        select,
        input{
            width: 100%;
            height: 35px;
            border-radius: 15px;
            border: 1px solid #939393;
            padding-left: 10px;
            margin-bottom: 25px;
            font-size: 16px;
        }
        textarea{
            width: 100%;
            height:300px;
            border-radius: 15px;
            border: 1px solid #939393;
            padding: 10px;
            margin-bottom: 25px;
            font-size: 16px;
        }
    }
    .block1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        .blockImgVideoPro{
            margin-bottom: 30px;
            input[type="file"]{
                height: 35px;
                opacity: 0;
                width: 250px;
                margin-bottom: 0;
            }
            label{
                margin-left: -300px;
                font-size: 16px;
                font-weight: 400;
                color: #2f2f2f;
            }
            .custom-file{
                input[type="file"]{
                    height: 35px;
                    opacity: 0;
                    width: 300px;
                }
            }
            .videoCropperPreview{
                margin-left: 300px;
                margin-top: -20px;
                img{
                    width: 190px;
                    height: 190px;
                    border-radius: 100%;
                    border: 4px solid #939393;
                }
                #deleteVideo2,
                #deleteVideo{
                    background: url('../img/Icon feather-trash-2@2x.png') top left no-repeat rgba(0,0,0,0);
                    background-size: contain;
                    color: rgba(0,0,0,0);
                    width: 20px;
                    height: 25px;
                    cursor: pointer;
                    margin-left: 15px;
                    position: relative;
                    z-index: 10000;
                }
            }
            .blockvideo{
                margin-top: 10px;
                video{
                    max-height: 250px;
                    max-width: 300px;
                    width: auto;
                }
            }
            #cropper{
                margin-bottom: 50px;
                .bgmodal{
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 9999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .img-preview-crop{
                        border: 5px solid #2f2f2f;
                    }
                    .containerModal {
                        width: 95%;
                        background: white;
                        position: relative;
                        padding: 30px;
                        height: 85vh;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        .box {
                        }
                        .cropBtn {
                            position: absolute;
                            bottom: 30px;
                            right: 39%;
                        }
                        .cropBtn2 {
                            position: absolute;
                            bottom: 30px;
                            right: 51%;
                        }
                    }
                }
                .imgCropperPreview{
                    img{
                        width: 190px;
                        height: 190px;
                        border-radius: 100%;
                        border: 4px solid #939393;
                    }
                    .btn.btn-warning{
                        background: url('../img/Icon feather-trash-2@2x.png') top left no-repeat rgba(0,0,0,0);
                        background-size: contain;
                        color: rgba(0,0,0,0);
                        width: 20px;
                        height: 25px;
                        cursor: pointer;
                    }
                }
            }
        }
        .blockInfoPro{
            width: 100%;

        }
        .blockagence{
            width: 100%;
            input{
                width: 338px;
                height: 47px;
                border-radius: 15px;
                border: 1px solid #939393;
                padding-left: 10px;
                margin-bottom: 25px;
                font-size: 16px;
            }


        }
    }
    .blockPerimetre{
        margin-top: 20px;
        width: 100%;
        h2{
            text-align: center;
            margin-bottom: 50px;
            font-size: 28px;
            color: #2F2F2F;
        }
        #perimetre{
            #lineselectperimetre{
                .blockSelectLine{
                    margin-bottom: 35px;
                    .block{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        .selectBlock{
                            width: 100%;
                            select{
                                width: 100%;
                                height: 35px;
                                border-radius: 15px;
                                text-align: center;
                                border: 2px solid #2f2f2f;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            .addperimettre{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                .btn{
                    width: 80px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span{
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .bloclService{
        margin-top: 50px;
        width: 100%;
        h2{
            text-align: center;
            margin-bottom: 50px;
            font-size: 28px;
            color: #2F2F2F;
        }
        .blockFull{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .block{
                .checkVente, .checkChasse, .checkRelation{
                    margin-bottom: 10px;
                }
                input[type='checkbox']{
                    border-radius: 100%;
                    width: auto;
                    height: auto;
                    border: unset;
                    padding-left: 0;
                    margin-bottom: 0;
                }
                label{
                    font-size: 16px;
                    font-weight: 400;
                    color: #939393;
                    margin-left: 5px;
                }
            }
        }
    }
    .blockpasswword{
        width: 100%;
        h2{
            margin: 20px 0 50px;
            font-size: 28px;
            color: #2F2F2F;
        }
        .form-control{
            width: 100%;
            margin: 0 auto;
            input{
                width: 100%;
                height: 35px;
                border-radius: 15px;
                border: 1px solid #939393;
                padding-left: 5px;
                margin-bottom: 25px;
            }
        }
    }
    .valide_form{
        text-align: center;
        margin: 20px 0 30px;
        input{
            background:#43a1db ;
            color: white;
            border-radius: 15px;
            border: none;
            padding: 15px 45px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            transition: 500ms;
            width: 184px;
            height: 50px;
            &:hover{
                background: #3186ba;
            }
        }
        span {
            display: none;
        }
    }
    .form-control{
        width: 100%;
        margin: 0 auto;
        text-align: center;
        input{
            width: auto;
            height: auto;
            padding-left: 0;
            margin-left: 0;
        }
        input[type="text"]{
            width: 100%;
            height: 35px;
            border-radius: 15px;
            border: 1px solid #939393;
            padding-left: 15px;
            margin-bottom: 25px;
        }
        select{

        }
        #telephone{
            margin-left: 70px;
        }
        h2{
            margin-bottom: 10px;
        }
        label{
            margin-left: 5px;
        }
    }
}
@media only screen and (min-width: 360px) {
    .blocknavigation{
        width: 90%;
        nav{
            ul{
                li{
                    &:last-child{
                    }
                    a{
                    }
                }
            }
        }
    }
    .blockcontent{
        width: 90%;
        h1{
        }
        h2{
        }
        h3{
        }
        .block1{
            .blockImgVideoPro{
                input[type="file"]{
                }
                label{
                }
                .custom-file{
                    input[type="file"]{
                    }
                }
                .videoCropperPreview{
                    img{
                    }
                    .btn.btn-warning{
                    }
                }
                #cropper{
                    .imgCropperPreview{
                        img{
                        }
                        .btn.btn-warning{
                        }
                    }
                }
            }
            .blockInfoPro{
                select,
                input{
                }
                textarea{
                }
            }
            .blockagence{
                input{
                }
            }
        }
        .blockPerimetre{
            h2{
            }
            #perimetre{
                #lineselectperimetre{
                    .blockSelectLine{
                        .block{
                            .selectBlock{
                                select{
                                }
                            }
                        }
                    }
                }
                .addperimettre{

                }
            }
        }
        .bloclService{
            h2{
            }
            .blockFull{
                .block{
                    .checkVente, .checkChasse, .checkRelation{
                    }
                    input[type='checkbox']{
                    }
                    label{
                    }
                }
            }
        }
        .blockpasswword{
            h2{
            }
            .form-control{
                input{
                }
            }
        }
        .valide_form{
            input{
                &:hover{
                }
            }
        }
        .form-control{
            input[type="text"]{
            }
            #telephone{
            }
            h2{
            }
            label{
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    .blocknavigation{
        nav{
            width: 100%;
            ul{
                display: flex;
                li{
                    width: auto;
                    border-left: 1px solid #939393;
                    border-bottom: none;
                    padding: 10px 20px;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:first-child{
                        border-left: none;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
        }
        .pro{
            ul{
                flex-wrap: wrap;
                li{
                    width: auto;
                    border-left: none;
                    border-bottom: 1px solid #939393;
                    padding: 10px 20px;
                    width: 33%;
                    &:nth-child(0n+4){
                        border-bottom: none;
                    }
                    a{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .blockcontent{
        h1{
        }
        h2{
        }
        h3{
        }
        .formAlign{
            select,
            input{
                width: 338px;
                height: 45px;
            }
            textarea{
                width: 338px;
            }
        }
        .block1{
            .blockImgVideoPro{
                input[type="file"]{
                }
                label{
                }
                .custom-file{
                    input[type="file"]{
                    }
                }
                .videoCropperPreview{
                    img{
                    }
                    .btn.btn-warning{
                    }
                }
                #cropper{
                    .imgCropperPreview{
                        img{
                        }
                        .btn.btn-warning{
                        }
                    }
                }
            }
            .blockInfoPro{
            }
            .blockagence{
                input{
                }
            }
        }
        .blockPerimetre{
            h2{
            }
            #perimetre{
                #lineselectperimetre{
                    .blockSelectLine{
                        .block{
                            .selectBlock{
                                width: 338px;
                                select{
                                    width: 338px;
                                }
                            }
                        }
                    }
                }
                .addperimettre{
                }
            }
        }
        .bloclService{
            h2{
            }
            .blockFull{
                .block{
                    .checkVente, .checkChasse, .checkRelation{
                    }
                    input[type='checkbox']{
                    }
                    label{
                    }
                }
            }
        }
        .blockpasswword{
            h2{
            }
            .form-control{
                input{
                }
            }
        }
        .valide_form{
            input{
                &:hover{
                }
            }
        }
        .form-control{
            input[type="text"]{
                width: 338px;
                height: 45px;
            }
            #telephone{
            }
            h2{
            }
            label{
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    .blocknavigation{
        nav{
            ul{
                li{
                    padding: 20px 50px;
                    &:first-child{
                    }
                    a{
                    }
                }
            }
        }
    }
    .blockcontent{
        h1{
        }
        h2{
        }
        h3{
        }
        .block1{

            flex-wrap: wrap;
            align-items: flex-start;
            .blockImgVideoPro{
                width: 100%;
                input[type="file"]{
                }
                label{
                }
                .custom-file{
                    input[type="file"]{
                    }
                }
                .videoCropperPreview{
                    img{
                    }
                    .btn.btn-warning{
                    }
                }
                #cropper{
                    .imgCropperPreview{
                        img{
                        }
                        .btn.btn-warning{
                        }
                    }
                }
            }
            .blockInfoPro{
                width: auto;
                select,
                input{
                }
                textarea{
                }
            }
            .blockagence{
                width: auto;
                input{
                }
            }
        }
        .blockPerimetre{
            h2{
            }
            #perimetre{
                #lineselectperimetre{
                    .blockSelectLine{
                        .block{
                            flex-direction: row;
                            flex-wrap: wrap;
                            width: 720px;
                            margin: 0 auto;
                            .selectBlock{
                                select{
                                }
                            }
                        }
                    }
                }
                .addperimettre{
                }
            }
        }
        .bloclService{
            h2{
            }
            .blockFull{
                flex-direction: row;
                .block{
                    width: 30%;
                    .checkVente, .checkChasse, .checkRelation{
                    }
                    input[type='checkbox']{
                    }
                    label{
                    }
                }
            }
        }
        .blockpasswword{
            h2{
            }
            .form-control{
                input{
                }
            }
        }
        .valide_form{
            input{
                &:hover{
                }
            }
        }
        .form-control{
            input[type="text"]{
            }
            #telephone{
            }
            h2{
            }
            label{
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .blocknavigation{
        width: 1200px;
        nav{
            ul{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 890px;
                margin:0 auto;
                li{
                    border-left: 1px solid #939393 ;
                    border-bottom: none;
                    padding: 20px 30px;
                    width: 300px;
                        text-align: center;
                    &:first-child{
                        border-left: none;
                    }
                    a{
                        font-size: 16px;
                        font-weight: 400;
                        color: #939393;
                    }
                    .currentLink{
                        color: #2f2f2f;
                    }
                }
            }
        }
        .pro{
            ul{
                flex-wrap: unset;
                li{
                    border-left: 1px solid #939393;
                    border-bottom: none;
                    height: 77px;
                    width: 25%;
                    &:first-child{
                    }
                    a{
                    }
                    .currentLink{
                    }
                }
            }
        }
    }
    .blockcontent{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        &.profilPart{
            margin-top: 50px;
            justify-content: center;
            .blockpasswword{
                position: relative;
                left: unset;
                top: unset;
            }
            .valide_form{
                position: relative;
                left: unset;
                top: unset;
            }
        }
        h1{
            display: block;
            width: 100%;
        }
        h2{
            font-size: 16px;
            font-weight: 400;
            color: #939393;
            text-align: center;
            width: 100%;
        }
        h3{
        }
        .block1{
            width: 300px;
            .blockImgVideoPro{
                &.center{
                    width: 300px;
                }
                width: auto;
                input[type="file"]{
                }
                label{
                }
                .custom-file{
                    input[type="file"]{
                    }
                }
                .videoCropperPreview{
                    img{
                        width: 190px;
                        height: 190px;
                        border-radius: 100%;
                        border: 4px solid #939393;
                    }
                    .btn.btn-warning{
                    }
                }
                #cropper{
                    .imgCropperPreview{
                        img{
                        }
                        .btn.btn-warning{
                        }
                    }
                }
            }
            .blockInfoPro{
                width: 450px;
                select,
                input{
                    width: 338px;
                    height: 47px;
                    border-radius: 15px;
                    border: 1px solid #939393;
                    padding-left: 10px;
                    margin-bottom: 25px;
                    font-size: 16px;
                }
                textarea{
                    width: 338px;
                    height:360px;
                    border-radius: 15px;
                    border: 1px solid #939393;
                    padding: 10px;
                    margin-bottom: 25px;
                    font-size: 16px;
                }
            }
            .blockagence{
                width: 450px;
                input{
                }
            }
            .valide_form{
                position: relative;
                left: unset;
                width: unset;
                top: unset;
                span {
                    display: block;
                    position: absolute;
                    top: 12px;
                    right: 96px;
                    color: white;
                    font-size: 22px;
                }
            }
        }
        .formAlign{
            width: 900px;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            flex-wrap: wrap;
            position: relative;
            height: 2150px;
            select,
            input{
                width: 338px;
                height: 47px;
                border-radius: 15px;
                border: 1px solid #939393;
                padding-left: 10px;
                margin-bottom: 25px;
                font-size: 16px;
            }
            textarea{
                width: 338px;
                height:360px;
                border-radius: 15px;
                border: 1px solid #939393;
                padding: 10px;
                margin-bottom: 25px;
                font-size: 16px;
            }
        }
        .blockPerimetre{
            position: absolute;
            left: -300px;
            top: 1750px;
            width: 1200px;
            h2{
            }
            #perimetre{
                #lineselectperimetre{
                    .blockSelectLine{
                        .block{
                            width: auto;
                            justify-content: flex-start;
                            .selectBlock{
                                width: auto;
                                margin-right: 13px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                select{
                                    width: 260px;
                                    height: 65px;
                                }
                            }
                        }
                    }
                }
                .addperimettre{

                }
            }
        }
        .bloclService{
            position: absolute;
            left: -300px;
            width: 1200px;
            top: 1980px;
            h2{
            }
            .blockFull{
                .block{
                    width: 30%;
                    .checkVente, .checkChasse, .checkRelation{
                    }
                    input[type='checkbox']{
                    }
                    label{
                    }
                }
            }
        }
        .blockpasswword{
            width: 1200px;
            position: absolute;
            left: -300px;
            top: 2410px;
            h2{
            }
            .form-control{
                width: 338px;
                margin: 0 auto;
                input{
                    width: 100%;
                    height: 47px;
                }
            }
        }
        #btn_submit_form_custom{
            top: 2770px;
        }
        .valide_form{
            position: absolute;
            left: -300px;
            width: 1200px;
            top: 2340px;
            input{
                width: 184px;
                padding-left: 45px;
                &:hover{
                }
            }
            span {
                display: block;
                position: absolute;
                top: 12px;
                right: 96px;
                color: white;
                font-size: 22px;
            }
        }
        .form-control{
            width: 338px;
            input[type="text"]{
            }
            #telephone{
                margin-left: 70px;
            }
            h2{
                margin-bottom: 10px;
            }
            label{
                margin-left: 5px;
            }
        }
    }
    .upfooter footer{
        margin-top: 750px;
    }
    .footerBotton{

        footer{

        }
    }
    #btn2{
        a{
            font-size: 16px;
        }
    }
}
#blockCopy{
    position: relative;
    height: 45px;
    margin-bottom: 20px;
}
#copy{
    height: 45px;
    padding: 12px;
    overflow: auto;
    outline: none;
    box-shadow: none;
    user-select: none;
    resize: none;
}
#copyBtn{
    position: absolute;
    display: block;
    background: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    svg{
        position: absolute;
        top: 9px;
        right: 15px;
    }
}

.copiedtext {
    position: absolute;
    left: 0; top: 0; right: 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-1em);
    color: #000;
    transition: all .500s;
}
.copied .copiedtext {
    opacity: 1;
    transform: translateY(-2em);
}
#card_ext{
    text-align: left;
}
#btnCartSee{
    display: block;
    background: #43a1db;
    color: white;
    border-radius: 15px;
    border: none;
    padding: 15px 45px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: 500ms;
    width: 280px;
    text-align: center;
    height: 50px;
    margin:0 auto 20px;
}
